<template>
  <b-card
    v-if="tableData"
    no-body
    class="card-company-table"
  >
    <b-table
      :items="tableData"
      responsive
      striped
      hover
      fixed
      show-empty
      :fields="fields"
      class="mb-50"
    >
      <!-- model-date -->
      <template #cell(date)="data">
        <div>
          {{ data.item.date | formatDate }}
        </div>
      </template>

      <!-- model-owner -->
      <template #cell(user)="data">
        <div>
          {{ getUserUsername(data.item.user) }}
        </div>
      </template>

      <!-- row-actions -->
      <template #cell(actions)="data">
        <div>
          <b-button-group size="sm" class="mx-auto">
            <b-button variant="outline-danger" data-testid="btn-delete-snapshot" @click="deleteSnapshot(data.item.id)">
              <feather-icon
                icon="TrashIcon"
                class="mr-50 text-danger"
              />
            </b-button>
            <b-button variant="outline-success" data-testid="btn-download-snapshot" @click="downloadSnapshot(data.item.id)">
              <feather-icon
                icon="DownloadIcon"
                class="mr-50 text-success"
              />
              <span>Download</span>
            </b-button>
          </b-button-group>
        </div>
      </template>
    </b-table>
    <delete-model-snapshot-modal :model-id="modelId" :snapshot-id="selectedSnapshot" @closed="snapshotModalClosed() " />
  </b-card>
</template>

<script>
import { BButton, BCard, BTable } from 'bootstrap-vue'
import DeleteModelSnapshotModal from '@/views/Models/DeleteModelSnapshot.vue'
import coreService from '@/libs/api-services/core-service'
import Vue from 'vue'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

export default {
  name: 'ModelTable',
  components: {
    DeleteModelSnapshotModal,
    BCard,
    BTable,
    BButton,
  },
  props: {
    tableData: {
      type: Array,
      default: () => [],
    },
    modelId: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      fields: [
        { key: 'date', label: 'Date', class: 'text-center' },
        { key: 'user', label: 'Created By', class: 'text-center' },
        { key: 'actions', label: 'Actions', class: 'text-center' },
      ],
      selectedSnapshot: '',
    }
  },
  methods: {
    downloadSnapshot(snapshotId) {
      this.selectedSnapshot = snapshotId
      console.log(this.selectedSnapshot)
      coreService
        .get(`/v1/model/${this.modelId}/snapshot/${this.selectedSnapshot}/download`, {
          responseType: 'blob', // Specify that the response is binary data
        })
        .then(response => {
          const fileUrl = window.URL.createObjectURL(new Blob([response.data], { type: 'application/gzip' }))
          const fileLink = document.createElement('a')
          fileLink.href = fileUrl
          const fileName = `${this.selectedSnapshot}.tar.gz`
          fileLink.setAttribute('download', fileName)
          document.body.appendChild(fileLink)
          fileLink.click()
        })
        .catch(e => {
          console.error(e)
          Vue.$toast({
            component: ToastificationContent,
            props: {
              title: 'Error',
              text: `There was an error attempting to download the snapshot.  ${e}`,
              icon: 'AlertTriangleIcon',
              variant: 'danger',
            },
          })
        })
    },
    deleteSnapshot(snapshotId) {
      this.selectedSnapshot = snapshotId
      this.$bvModal.show('delete-model-snapshot-modal')
    },
    snapshotModalClosed() {
      this.$emit('snapshot-modal-closed', '')
    },
  },
}
</script>

<style lang="scss" scoped>
@import 'src/assets/scss/style.scss';

.col-divider > * + * {
  border-top: 0;
  border-bottom: 0;

  --tw-divide-x-reverse: 0;
  border-right-width: calc(1px * var(--tw-divide-x-reverse));
  border-left-width: calc(1px * calc(1 - var(--tw-divide-x-reverse)));

  --tw-divide-opacity: 0.1;
  border-color: rgba(0, 0, 0, var(--tw-divide-opacity));
  border-style: solid;
}

.row-details {
  @extend .border-rounded-lg;
  background: whitesmoke;
}

.dark-layout {
  .row-details {
    background: rgba(255, 255, 255, 0.05)
  }

  .col-divider > * + * {
    --tw-divide-opacity: 0.1;
    border-color: rgba(249, 250, 251, var(--tw-divide-opacity));
  }
}
</style>
