<template>
  <b-modal
    id="delete-model-snapshot-modal"
    centered
    title="Delete Snapshot"
    @ok="deleteSnapshot"
  >
    <div
      id="delete-evidence-modal-body"
      class="p-1"
    >
      Are you sure you want to delete this snapshot? This can not be undone.
    </div>
    <template #modal-footer="{ ok, cancel }">
      <b-button
        variant="outline-secondary"
        :disabled="loading"
        @click="cancel"
      >
        No, keep it
      </b-button>

      <b-button
        variant="danger"
        :disabled="loading"
        @click="ok"
      >
        Delete
      </b-button>
    </template>
  </b-modal>
</template>

<script>
import coreService from '@/libs/api-services/core-service'
import Vue from 'vue'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

export default {
  name: 'DeleteModelSnapshot',
  props: {
    modelId: {
      type: String,
      required: true,
    },
    snapshotId: {
      type: String,
      required: true,
    },
  },
  data: () => ({
    loading: false,
  }),
  methods: {
    deleteSnapshot() {
      this.loading = true
      coreService
        .delete(`/v1/model/${this.modelId}/snapshot/${this.snapshotId}`)
        .then(() => {
          Vue.$toast({
            component: ToastificationContent,
            props: {
              title: 'Snapshot Deleted',
              text: 'The Snapshot has been successfully deleted.',
              icon: 'EditIcon',
              variant: 'success',
            },
          })
        })
        .catch(e => {
          console.error(e)
          Vue.$toast({
            component: ToastificationContent,
            props: {
              title: 'Error',
              text: `There was an error attempting to delete the snapshot.  ${e}`,
              icon: 'AlertTriangleIcon',
              variant: 'danger',
            },
          })
        })
        .finally(() => {
          this.loading = false
          this.$emit('closed', '')
          this.$bvModal.hide('delete-model-snapshot-modal')
        })
    },
  },
}
</script>

<style scoped>

</style>
